import { CheckOutlined } from "@ant-design/icons";
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Dropdown, Input, Modal, Row,Select,Space,Badge } from "antd";
import { useCallback, useState,useEffect } from "react";
import { connect } from "react-redux";
import { onLocaleChange } from "redux/actions/Theme";
import { LOGIN_DATA } from "redux/constants/Auth";
import { IMAGE_PREFIX } from "redux/constants/ImagePrefix";
const { Option } = Select;
const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}
const onSearch = (value) => {
  // console.log('search:', value);
};
const onChangeRestaurant = (id) => {
  // console.log(id);
  userD.restaurant_id = id;
  localStorage.setItem(LOGIN_DATA, JSON.stringify(userD));
  window.location.reload();
};

export const NavLanguage = ({ data }) => {
  const [search, setSearch] = useState("");
  // const [renderList, setRenderList] = useState([]);

 
  const renderList = data
    ?.filter(({ title }) => title.match(new RegExp(search, "i")))
    .map((item) => {
      return (
       
          <Option key={item.id} value={item.id} title={item.title}>
                <Row style={{ alignItems: "center",}}>
               
                <Avatar
                    className="mr-2"
                    size={38}
                    src={IMAGE_PREFIX + item.profile_image}
                  />
                   <h4 className="mb-0">
                    {item.title}
                    {rest_id == item.id ? (
                      // <CheckOutlined
                      //   className="ml-2 text-success"
                      //   style={{ float: "right", fontSize: "20px",justifyContent:"center" }}
                      // />
                        

                      <Badge status="success" style={{ marginLeft:6}} />
                    ) : null}{" "}
                  </h4>
                  </Row>
                </Option>
               
          
      );
    });
const arra =[];
   data?.filter(({ title }) => title.match(new RegExp(search, "i")))
  .map((item,i) => (
    arra.push({value:item.id,
    label:item.title,
   image:IMAGE_PREFIX + item.profile_image
    })
  )
  );
// console.log(arra);
          
useEffect(() => {

}, [search]);    



  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
const rendoption = (a)=>
{
// console.log(a);
}
  return (
    <>

      <div>
        <Select
          // allowClear
          // showSearch
          
          style={{
          alignItems:"center",
            width: 250,
            margin:10,
          }}
          placeholder="Select your Restaurant"
          // dropdownRender={renderList}
         
          onChange={onChangeRestaurant}
          // optionRender={(a)=>{<div style={{color:"red"}}>{a}</div>}}
          value={rest_id}
          // options={arra}
          optionFilterProp="children"
          filterOption={(input, option) =>{
          return(option.order.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
          option.order.toLowerCase().indexOf(input.toLowerCase()) >= 0
);
          }
          }
        >
          {renderList}
        
        </Select>
      </div>

      {/* <div>
        <Button type="text" onClick={showModal}>
          Switch Restaurant
        </Button>
        <Modal
          title="Select Restaurant"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={1500}
        >
          <div style={{ marginBottom: 10 }}>
            <Input
              placeholder="Search your Restaurant"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>

          <div
            className="site-card-wrapper"
            style={{ overflow: "auto", overflowX: "hidden", height: "31.25vw" }}
          >
            <Row gutter={[16, 16]}>{renderList}</Row>
          </div>
        </Modal>
      </div> */}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps, { onLocaleChange })(NavLanguage);
