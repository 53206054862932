export const RESTAURANT_TYPE = "RESTAURANT_TYPE";

export const ADD_RESTAURANT_TYPE = "ADD_RESTAURANT_TYPE";

export const LIST_RESTAURANT_TYPE = "LIST_RESTAURANT_TYPE";

export const RESTAURANT_TYPE_DETAILS = "RESTAURANT_TYPE_DETAILS";

export const RESTAURANT_TYPE_DELETE = "RESTAURANT_TYPE_DELETE";

export const UPDATE_RESTAURANT_TYPE = "UPDATE_RESTAURANT_TYPE";

export const ASSIGN_RESTAURANT_TYPE = "ASSIGN_RESTAURANT_TYPE";

export const LIST_ASSIGN_RESTAURANT_TYPE = "LIST_ASSIGN_RESTAURANT_TYPE";

export const ASSIGN_RESTAURANT_TYPE_DETAILS = "ASSIGN_RESTAURANT_TYPE_DETAILS";
