export const FEATURES = "FEATURES";

export const ADD_FEATURES = "ADD_FEATURES";

export const LIST_FEATURES = "LIST_FEATURES";

export const FEATURES_DETAILS = "FEATURES_DETAILS";

export const FEATURES_DELETE = "FEATURES_DELETE";

export const UPDATE_FEATURES = "UPDATE_FEATURES";

export const ASSIGN_FEATURES = "ASSIGN_FEATURES";

export const LIST_ASSIGN_FEATURES = "LIST_ASSIGN_FEATURES";

export const ASSIGN_FEATURES_DETAILS = "ASSIGN_FEATURES_DETAILS";

export const SHOW_FEATURE_MESSAGE = "SHOW_FEATURE_MESSAGE";

export const HIDE_FEATURE_MESSAGE = "HIDE_FEATURE_MESSAGE";
