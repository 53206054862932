import {
  ADD_MENU_IMAGE,
  MENU_IMAGE,
  MENU_IMAGE_DETAILS,
  MENU_IMAGE_DELETE,
  UPDATE_MENU_IMAGE,
} from "redux/constants/MenuConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const MenuImageReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_MENU_IMAGE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        menuimageData: action.menuimageData,
      };
    case MENU_IMAGE:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case MENU_IMAGE_DETAILS:
      return {
        ...state,
        redirect: "",
        menuimageData: action.payload,
        token: action.token,
      };
    case MENU_IMAGE_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_MENU_IMAGE:
      return {
        ...state,
        redirect: "",
        menuimageData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default MenuImageReducer;
