import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_OFFERS,
  LIST_OFFERS,
  OFFERS_DELETE,
  UPDATE_OFFERS,
} from "redux/constants/OffersConstants";
import { offersdetails, offersdelete } from "redux/actions/OffersAction";
import { LOGIN_DATA } from "redux/constants/Auth";
import axios from "axios";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertOffersData() {
  yield takeEvery(ADD_OFFERS, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(
        `/add_restaurant_offers/${rest_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getOffers() {
  yield takeEvery(LIST_OFFERS, function* () {
    try {
      const res = yield axios.get(`/get_restaurant_offer/${rest_id}`);
      if (res.status === 200) {
        yield put(offersdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateOffers() {
  yield takeEvery(UPDATE_OFFERS, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_restaurant_offer/${payload.offer_image_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* offersDeleteData() {
  yield takeEvery(OFFERS_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/delete_restaurant_offerimages/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertOffersData),
    fork(getOffers),
    fork(updateOffers),
    fork(offersDeleteData),
  ]);
}
