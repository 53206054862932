import {
  ADD_OFFERS,
  LIST_OFFERS,
  OFFERS_DETAILS,
  OFFERS_DELETE,
  UPDATE_OFFERS,
} from "redux/constants/OffersConstants";

export const addOffers = (data) => {
  return {
    type: ADD_OFFERS,
    payload: data,
  };
};

export const listoffers = () => {
  return {
    type: LIST_OFFERS,
  };
};

export const offersdetails = (data) => {
  return {
    type: OFFERS_DETAILS,
    payload: data,
  };
};

export const offersdelete = (id) => {
  return {
    type: OFFERS_DELETE,
    payload: id,
  };
};

export const updateOffers = (data) => {
  return {
    type: UPDATE_OFFERS,
    payload: data,
  };
};
