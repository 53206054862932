import {
  ADDTABLETYPES,
  LIST_TABLETYPES,
  TABLETYPES_DETAILS,
  TABLE_TYPE_DELETE,
  UPDATE_TABLE_TYPES
} from "redux/constants/TableTypeConstants";

export const addTableType = (data) => {
  return {
    type: ADDTABLETYPES,
    payload: data,
  };
};

export const listtabletype = () => {
  return {
    type: LIST_TABLETYPES,
  };
};

export const tabletypedetails = (data) => {
  return {
    type: TABLETYPES_DETAILS,
    payload: data,
  };
};

export const tabletypedelete = (id) => {
  return {
    type: TABLE_TYPE_DELETE,
    payload: id,
  };
};

export const updateTableType = (data) => {
  return {
    type: UPDATE_TABLE_TYPES,
    payload: data,
  };
};