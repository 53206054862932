import {
  ADDRESTAURANT,
  RESTAURANT,
  RESTAURANT_DETAILS,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  GET_VENDOR_DETAILS,
  LIST_RESTAURANT_CUSTOMER,
  RESTAURANT_CUSTOMER_DETAILS,
  WHATSAPP_DETAILS,
  GET_WHATSAPP,
  FETCHER_DETAILS,
  WHATSAPP_FETCHER,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_DETAILS,
} from "redux/constants/RestaurantConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
};

const RestaurantReducer = (state = initState, action) => {
// console.log(action);
  switch (action.type) {
    case ADDRESTAURANT:
      return {
        ...state,
        redirect: "",
        token: action.token,
        restaurantData: action.restaurantData,
      };
      case UPLOAD_IMAGE:
        return {
          ...state,
          redirect: "",
          token: action.token,
          restaurantData: action.restaurantData,
        };
        case UPLOAD_IMAGE_DETAILS:
        return {
          ...state,
          redirect: "",
          token: action.token,
          imageData: action.payload,
        };
    case RESTAURANT:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case RESTAURANT_DETAILS: {
      return {
        ...state,
        redirect: "",
        restaurantData: action.restdata,
        token: action.token,
      };
    }

    case RESTAURANT_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case UPDATE_RESTAURANT: {
      return {
        ...state,
        redirect: "",
        restaurantData: action.restaurantData,
        token: action.token,
      };
    }

    case GET_VENDOR:
      return {
        ...state,
        redirect: "",
        token: action.token,
        // vendorData: action.payload,
      };

    case GET_VENDOR_DETAILS: {
      return {
        ...state,
        redirect: "",
        vendorData: action.payload,
        token: action.token,
      };
    }
    case LIST_RESTAURANT_CUSTOMER:
      return {
        ...state,
        redirect: "",
        token: action.token,
        loading:true,
      };
    case RESTAURANT_CUSTOMER_DETAILS: {
      return {
        ...state,
        redirect: "",
        restaurantCustomerData: action.payload,
        token: action.token,
        loading:false,
      };
    }
    case GET_WHATSAPP:
      return {
        ...state,
        redirect: "",
        token: action.token,
        loading:true,
        // filter:action.data
      };
    case WHATSAPP_DETAILS: {
      return {
        ...state,
        redirect: "",
        whatsappData: action.payload,
        token: action.token,
        loading:false,
      };
    }
    case WHATSAPP_FETCHER:
      return {
        ...state,
        redirect: "",
        token: action.token,
        loading:true,
        url:action.url,
        authtoken:action.authtoken,
      };
    case FETCHER_DETAILS: {
      return {
        ...state,
        redirect: "",
        whatsappTemplate: action.payload,
       
        loading:false,
      };
    }
    default:
      return state;
  }
};

export default RestaurantReducer;
