import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  AUTH_TOKEN,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  LOGIN_DATA,
  ADDFLOOR,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  addFloor,
} from "../actions/Auth";
import axios from "axios";

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const res = yield axios.post(`/login`, payload);

      if (res.data.status === 401) {
        yield put(showAuthMessage(res.data.error));
      } else {
        localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
        yield put(authenticated(res.data.data.token, res.data.data.user));
        window.location.reload();
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const signOutUser = yield axios.get(`/logout`);
      if (signOutUser.data.status === true) {
        localStorage.removeItem(AUTH_TOKEN);
        yield put(signOutSuccess(signOutUser.data.message));
      } else {
        yield put(showAuthMessage(signOutUser.data.message));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
  ]);
}
