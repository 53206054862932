import {
  DashboardOutlined,
  FundOutlined,
  FileDoneOutlined,
  UserOutlined,
  InfoOutlined,
  CoffeeOutlined,
  FileSearchOutlined,
  ClockCircleOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React from "react";
import {
  RiReservedLine,
  RiFolderHistoryLine,
  RiUser2Line as RiFolderUserFill,
} from "react-icons/ri";
import { LOGIN_DATA } from "redux/constants/Auth";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let user_type = "";
if (userD) {
  user_type = userD.user_type;
}

const iconFaBeer = () => {
  return <RiReservedLine style={{ marginRight: 24,justifyContent: 'center',marginLeft:-2,marginBottom:-4}} size={18} />;
};

const iconRiFolderHistoryLine = () => {
  return <RiFolderHistoryLine style={{ marginRight: 10 }} size={20} />;
};

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "sidenav.dashboard.sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const reservations = [
  {
    key: "reservation",
    // path: `${APP_PREFIX_PATH}/reservation`,
    title: "sidenav.reservation",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "create-reservation",
        path: `${APP_PREFIX_PATH}/reservation/create-reservation`,
        title: "sidenav.reservation.add",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "reservation-list",
        path: `${APP_PREFIX_PATH}/reservation/addreservationmanage`,
        title: "sidenav.reservation.list",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "customer-list",
        path: `${APP_PREFIX_PATH}/customer/addcustomer`,
        title: "sidenav.customer.list",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },

      // {
      //   key: "reservation-history",
      //   path: `${APP_PREFIX_PATH}/reservation/history`,
      //   title: "sidenav.reservation.history",
      //   icon: ClockCircleOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const restaurantManagement = [
  {
    key: "restaurant.management",
    // path: `${APP_PREFIX_PATH}/restaurants`,
    title: "sidenav.restaurant.management",
    icon: CoffeeOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "table.management",
        path: `${APP_PREFIX_PATH}/tablemanagement/addtablemanagement`,
        title: "sidenav.table.management",
        icon: CoffeeOutlined,
        breadcrumb: false,
        submenu: [
          // {
          //   key: "floor-list",
          //   path: `${APP_PREFIX_PATH}/floors/listfloor`,
          //   title: "sidenav.floor.list",
          //   icon: DashboardOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "table-list",
          //   path: `${APP_PREFIX_PATH}/table/listtable`,
          //   title: "sidenav.table.list",
          //   icon: DashboardOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
          // {
          //   key: "tabletype-list",
          //   path: `${APP_PREFIX_PATH}/tabletypes/listtabletype`,
          //   title: "sidenav.tabletype.list",
          //   icon: DashboardOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },

      {
        key: "about.section",
        path: `${APP_PREFIX_PATH}/aboutsection/addaboutsection`,
        title: "sidenav.about.section",
        icon: InfoOutlined,
        breadcrumb: false,
        submenu: [],
      },

      // {
      //   key: "about.section",
      //   // path: `${APP_PREFIX_PATH}/floors`,
      //   title: "sidenav.about.section",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: "restaurantimage-list",
      //       path: `${APP_PREFIX_PATH}/restaurantimage/listrestaurantimage`,
      //       title: "sidenav.restaurantimage.list",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },

      //     {
      //       key: "offers-list",
      //       path: `${APP_PREFIX_PATH}/offers/listoffers`,
      //       title: "sidenav.offers.list",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },

      //     {
      //       key: "menuimage-list",
      //       path: `${APP_PREFIX_PATH}/menuimage/listmenuimage`,
      //       title: "sidenav.menuimage.list",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },

      //     {
      //       key: "about-list",
      //       path: `${APP_PREFIX_PATH}/about/listabout`,
      //       title: "sidenav.about.list",
      //       icon: IdcardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },

      //     {
      //       key: "location-list",
      //       path: `${APP_PREFIX_PATH}/location/listlocation`,
      //       title: "sidenav.location.list",
      //       icon: DashboardOutlined,
      //       breadcrumb: false,
      //       submenu: [],
      //     },
      //   ],
      // },
    ],
  },
];

const oneDisco = [
  {
    key: "disco",
    path: `${APP_PREFIX_PATH}/category`,
    title: "sidenav.one.disco",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "discovery",
        path: `${APP_PREFIX_PATH}/discovery/adddiscovery`,
        title: "sidenav.discovery",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "onedisco-category",
      //   path: `${APP_PREFIX_PATH}/category/listcategory`,
      //   title: "sidenav.onedisco.category",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-cuisine-list",
      //   path: `${APP_PREFIX_PATH}/cuisine/listcuisine`,
      //   title: "sidenav.onedisco.cuisine",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-features-list",
      //   path: `${APP_PREFIX_PATH}/features/listfeatures`,
      //   title: "sidenav.onedisco.features",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-tags-list",
      //   path: `${APP_PREFIX_PATH}/tags/listtags`,
      //   title: "sidenav.onedisco.tags",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const restaurant = [
  {
    key: "restaurant",
    // path: `${APP_PREFIX_PATH}/restaurants`,
    title: "sidenav.restaurant",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "restaurant.create",
        path: `${APP_PREFIX_PATH}/restaurants/addrestaurant`,
        title: "sidenav.restaurant",
        icon: iconFaBeer,
        // icon: DashboardOutlined,

        breadcrumb: false,
        submenu: [],
      },
      {
        key: "onedisco-management",
        path: `${APP_PREFIX_PATH}/discomanagement/listdiscomanagement`,
        title: "sidenav.onedisco.management",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "restaurant-add",
      //   path: `${APP_PREFIX_PATH}/restaurants/addrestaurant`,
      //   title: "sidenav.restaurant.add",
      //   icon: iconFaBeer,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "restaurant-list",
      //   path: `${APP_PREFIX_PATH}/restaurants/listrestaurant`,
      //   title: "sidenav.restaurant.list",
      //   icon: iconRiFolderHistoryLine,
      //   breadcrumb: false,
      //   submenu: [],
      // },

     

      // {
      //   key: "onedisco-assign-category",
      //   path: `${APP_PREFIX_PATH}/assigncategory/listassigncategory`,
      //   title: "sidenav.onedisco.assign.category",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-cuisine",
      //   path: `${APP_PREFIX_PATH}/assigncuisine/listassigncuisine`,
      //   title: "sidenav.onedisco.assign.cuisine",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-features",
      //   path: `${APP_PREFIX_PATH}/assignfeatures/listassignfeatures`,
      //   title: "sidenav.onedisco.assign.features",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-tags",
      //   path: `${APP_PREFIX_PATH}/assigntags/listassigntags`,
      //   title: "sidenav.onedisco.assign.tags",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const oneDiscoAdmin = [
  {
    key: "disco",
    // path: `${APP_PREFIX_PATH}/assigncategory`,
    title: "sidenav.one.disco.admin",
    icon: FileSearchOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "onedisco-management",
        path: `${APP_PREFIX_PATH}/discomanagement/listdiscomanagement`,
        title: "sidenav.onedisco.management",
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "onedisco-assign-category",
      //   path: `${APP_PREFIX_PATH}/assigncategory/listassigncategory`,
      //   title: "sidenav.onedisco.assign.category",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-cuisine",
      //   path: `${APP_PREFIX_PATH}/assigncuisine/listassigncuisine`,
      //   title: "sidenav.onedisco.assign.cuisine",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-features",
      //   path: `${APP_PREFIX_PATH}/assignfeatures/listassignfeatures`,
      //   title: "sidenav.onedisco.assign.features",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },

      // {
      //   key: "onedisco-assign-tags",
      //   path: `${APP_PREFIX_PATH}/assigntags/listassigntags`,
      //   title: "sidenav.onedisco.assign.tags",
      //   icon: DashboardOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
    ],
  },
];

const message_campaign = [
  {
    key: "message_campaign",
    title: "sidenav.message_campaign",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      // {
      //   key: "message_campaign.create",
      //   path: `${APP_PREFIX_PATH}/message-campaign/create`,
      //   title: "sidenav.message_campaign.create",
      //   icon: iconFaBeer,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "message_campaign.listing",
        path: `${APP_PREFIX_PATH}/message-campaign/listing`,
        title: "sidenav.message_campaign.listing",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const users = [
  {
    key: "users",
    title: "sidenav.user_management",
    icon: DashboardOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "sidenav.users",
        path: `${APP_PREFIX_PATH}/users`,
        title: "sidenav.users",
        icon: () => <RiFolderUserFill style={{ marginRight: 10 }} size={20} />,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

let navigationConfig = [];
{
  if (user_type == 1) {
    navigationConfig = [
      ...dashBoardNavTree,
      ...reservations,
      ...restaurantManagement,
      ...oneDisco,
      ...restaurant,
      ...message_campaign,
      ...users,
    ];
  } else {
    navigationConfig = [
      ...dashBoardNavTree,
      ...reservations,
      ...restaurantManagement,
      ...oneDiscoAdmin,
      ...message_campaign,
      
      // ...users,
    ];
  }
}

export default navigationConfig;
