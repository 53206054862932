import {
  ADD_CUISINE,
  CUISINE,
  CUISINE_DETAILS,
  CUISINE_DELETE,
  UPDATE_CUISINE,
  ASSIGN_CUISINE,
  ASSIGN_CUISINE_DETAILS,
} from "redux/constants/CuisineConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const CousinsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_CUISINE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        cuisineData: action.cuisineData,
      };
    case CUISINE:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case CUISINE_DETAILS: {
      return {
        ...state,
        redirect: "",
        cuisineData: action.payload,
        token: action.token,
      };
    }

    case CUISINE_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case UPDATE_CUISINE: {
      return {
        ...state,
        redirect: "",
        cuisineData: action.payload,
        token: action.token,
      };
    }

    case ASSIGN_CUISINE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        assignCuisineData: action.assignCuisineData,
      };

    case ASSIGN_CUISINE_DETAILS:
      return {
        ...state,
        redirect: "",
        assignCuisineData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default CousinsReducer;
