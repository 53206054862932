import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADDRESTAURANT,
  LIST_RESTAURANT,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  RESTAURANT,
  LIST_RESTAURANT_CUSTOMER,
  GET_WHATSAPP,
  WHATSAPP_FETCHER,
  UPLOAD_IMAGE,
} from "redux/constants/RestaurantConstants";
import {
  addRestaurant,
  restaurantdetails,
  vendordetails,
  restaurantcustomerdetails,
  whatsappdetails,
  whatsappfetcher,
  fetcherdetails,
  uploadimagedetails,
} from "redux/actions/RestaurantAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";

if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertRestaurantData() {
  yield takeEvery(ADDRESTAURANT, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/getvendor_restaurant_store_update`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getRestaurant() {
  yield takeEvery(LIST_RESTAURANT, function* () {
    try {
      const res = yield axios.get(`/getvendor_restaurant`);
      if (res.status === 200) {
        yield put(restaurantdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}


export function* getRestaurantCustomer() {
  yield takeEvery(LIST_RESTAURANT_CUSTOMER, function* () {
    try {
      const res = yield axios.post(`/restaurant_customer/${rest_id}`);
      if (res.status === 200) {
        // console.log("saga",res.data)
        yield put(restaurantcustomerdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}
export function* getVendor() {
  yield takeEvery(GET_VENDOR, function* () {
    try {
      const res = yield axios.get(`/getvendor/${rest_id}`);
      // console.log('res vendor',res);
      if (res.status === 200) {
        yield put(vendordetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateRestaurant() {
  yield takeEvery(UPDATE_RESTAURANT, function* ({ payload }) {
    console.log("payloadd", payload);
    try {
      const res = yield axios.post(
        `/getvendor_restaurant_store_update/${payload.id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}
export function* getWhatsappDetails() {
  yield takeEvery(GET_WHATSAPP, function* () {
    try {
   
      const res = yield axios.get(`/whatsapp_business_tokens/${rest_id}`);
      // console.log("res",res);
      if (res.status === 200) {
        // console.log(res.data.access_token);
        // console.log()
        yield put(whatsappdetails(res.data));
        // yield put(whatsappfetcher(process.env.REACT_APP_WHATSAPP_URL +
        //   `?name=${payload.search}&status=${payload.status}&before=${payload.before}&after=${payload.after}`,res.data.access_token));
          // whatsappfetcher(,res.data.access_token)
        } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}
export function* WhatsappFetcher() {
  yield takeEvery(WHATSAPP_FETCHER, function* ({url,authtoken}) {
    console.log("hi",url)
    try {
      const response= fetch(url, {
        headers: {
          Authorization: `Bearer ${authtoken}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((r) => r.json());
      // const res = yield axios.get(`/whatsapp_business_tokens/${rest_id}`);
      console.log("res",response);
    
     
        // console.log(res);
        yield put(fetcherdetails(response));
     
    } catch (err) {
      console.log("Err", err);
    }
  });
}
export function* uploadImage() {
  yield takeEvery(UPLOAD_IMAGE, function* ({ payload }) {
    console.log(payload);
    try {
      const res = yield axios.post(
        `/whatsapp/upload-template-image`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (res.status === 200) {
        yield put(uploadimagedetails(res.data));
        // message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getVendor),
    fork(insertRestaurantData),
    fork(getRestaurant),
    fork(updateRestaurant),
    fork(getRestaurantCustomer),
    fork(getWhatsappDetails),
    fork(WhatsappFetcher),
    fork(uploadImage),

    
    
  ]);
}
