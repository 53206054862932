import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADDRESERVATION,
  LIST_RESERVATION,
  LIST_RESERVE_BY,
  LIST_SOURCE_BY,
  LIST_WAITING,
  LIST_WALKIN,
  LIST_HISTORY_RESERVATION,
  GET_USER_PHONE,
  LIST_DELETED_RESERVATION
} from "redux/constants/ReservationConstants";
import axios from "axios";
import {
  reservebydetails,
  sourcebydetails,
  waitingdetails,
  walkindetails,
  historyreservationdetails,
  reservationdetails,
  getuserphonedetails,
  deletedReservationDetails
} from "../actions/ReservationAction";
import { LOGIN_DATA } from "redux/constants/Auth";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";

if (userD) {
  rest_id = userD.restaurant_id;
}

export function* getReserveBy() {
  yield takeEvery(LIST_RESERVE_BY, function* () {
    try {
      const res = yield axios.get(`/get_reserve_by/${rest_id}`);
      // console.log('reserve', res.data)
      if (res.status === 200) {
        yield put(reservebydetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getSourceBy() {
  yield takeEvery(LIST_SOURCE_BY, function* () {
    try {
      const res = yield axios.get(`/get_source`);
      if (res.status === 200) {
        yield put(sourcebydetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* insertReservationData() {
  yield takeEvery(ADDRESERVATION, function* ({ payload }) {
    try {
      const res = yield axios.post(`/add_reservation_by_id/${rest_id}`, payload);

      if (res.status === 200) {
        // console.log(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getWalkin() {
  yield takeEvery(LIST_WALKIN, function* ({ payload }) {
    try {
      const res = yield axios.post(`/is-walking/${rest_id}`, payload);
      // console.log('res', res)
      // const res = yield axios.post(`/get_reservation_walk_in`, payload);
      if (res.status === 200) {
        yield put(walkindetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getWaiting() {
  yield takeEvery(LIST_WAITING, function* () {
    try {
      const res = yield axios.get(`/get_reservation_waiting/0/500`);
      // console.log('res', res)
      if (res.status === 200) {
        yield put(waitingdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getReservation() {
  yield takeEvery(LIST_RESERVATION, function* ({ payload }) {
    try {
      const res = yield axios.post(`/is-reservation/${rest_id}`, payload);
      // console.log('resd', res)
      if (res.status === 200) {
        yield put(reservationdetails(res.data));
      } 
      // else {
      //   // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
      //   // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      // }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getHistoryReservation() {
  yield takeEvery(LIST_HISTORY_RESERVATION, function* ({ payload }) {
    try {
      const res = yield axios.post(`/is-reservation_seated/${rest_id}`, payload);
      // console.log('resd', res)
      if (res.status === 200) {
          // console.log('reservation', res.data.data);
        yield put(historyreservationdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getUserPhone() {
  yield takeEvery(GET_USER_PHONE, function* ({ payload }) {
    // console.log('pay load', payload);
    const data = {
      phone: payload
    }
    try {
      const res = yield axios.post(`/search-user-phone`, data);
      if (res.status === 200) {
          console.log('reservation', res.data);
        yield put(getuserphonedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getDeletedReservation() {
  yield takeEvery(LIST_DELETED_RESERVATION, function* ({ payload }) {
    try {
      const res = yield axios.post(`/is-reservation_deleted/${rest_id}`, payload);
      // console.log('res', res)
      // const res = yield axios.post(`/get_reservation_walk_in`, payload);
      if (res.status === 200) {
        yield put(deletedReservationDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getReserveBy),
    fork(getSourceBy),
    fork(insertReservationData),
    fork(getWalkin),
    fork(getWaiting),
    fork(getHistoryReservation),
    fork(getReservation),
    fork(getUserPhone),
    fork(getDeletedReservation),
  ]);
}
