import {
  ADD_FEATURES,
  LIST_FEATURES,
  FEATURES_DETAILS,
  FEATURES_DELETE,
  UPDATE_FEATURES,
  ASSIGN_FEATURES,
  LIST_ASSIGN_FEATURES,
  ASSIGN_FEATURES_DETAILS,
  SHOW_FEATURE_MESSAGE,
  HIDE_FEATURE_MESSAGE
} from "redux/constants/FeaturesConstants";

export const addFeatures = (data) => {
  return {
    type: ADD_FEATURES,
    payload: data,
  };
};

export const listFeatures = () => {
  return {
    type: LIST_FEATURES,
  };
};

export const featuresdetails = (data) => {
  return {
    type: FEATURES_DETAILS,
    payload: data,
  };
};

export const featuresdelete = (id) => {
  return {
    type: FEATURES_DELETE,
    payload: id,
  };
};

export const updateFeatures = (data) => {
  return {
    type: UPDATE_FEATURES,
    payload: data,
  };
};

export const assignFeatures = (data) => {
  return {
    type: ASSIGN_FEATURES,
    payload: data,
  };
};

export const listAssignFeatures = () => {
  return {
    type: LIST_ASSIGN_FEATURES,
  };
};

export const assignfeaturesdetails = (data) => {
  return {
    type: ASSIGN_FEATURES_DETAILS,
    payload: data,
  };
};

export const showFeatureMessage = (message) => {
  return {
    type: SHOW_FEATURE_MESSAGE,
    message,
  };
};

export const hideFeatureMessage = () => {
  return {
    type: HIDE_FEATURE_MESSAGE,
  };
};
