import {
  ADD_LOCATION,
  LOCATION,
  LOCATION_DETAILS,
  LOCATION_DELETE,
  UPDATE_LOCATION,
} from "redux/constants/LocationConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const LocationReducer = (state = initState, action) => {
    switch (action.type) {
      case ADD_LOCATION:
        return {
          ...state,
          redirect: "",
          token: action.token,
          locationData: action.locationData,
        };
      case LOCATION:
        return {
          ...state,
          redirect: "",
          token: action.token,
        };
      case LOCATION_DETAILS:
        return {
          ...state,
          redirect: "",
          locationData: action.payload,
          token: action.token,
        };
      case LOCATION_DELETE:
        return {
          ...state,
          token: action.token,
          data: action.payload,
        };
      case UPDATE_LOCATION:
        return {
          ...state,
          redirect: "",
          locationData: action.payload,
          token: action.token,
        };
      default:
        return state;
    }
  };
  
  export default LocationReducer;
  