import {
  RESTAURANT_STATS,
  RESTAURANT_STATS_DETAILS,
} from "redux/constants/RestaurantStats";

export const restaurantstats = (data) => {
  return {
    type: RESTAURANT_STATS,
    payload: data,
  };
};

export const restaurantstatsdetails = (data) => {
  return {
    type: RESTAURANT_STATS_DETAILS,
    payload: data,
  };
};
