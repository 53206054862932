export const RESTAURANT = 'RESTAURANT'; 

export const ADDRESTAURANT = 'ADDRESTAURANT'; 

export const LIST_RESTAURANT = 'LIST_RESTAURANT'

export const RESTAURANT_DETAILS = 'RESTAURANT_DETAILS'

export const RESTAURANT_DELETE = 'RESTAURANT_DELETE'

export const UPDATE_RESTAURANT = 'UPDATE_RESTAURANT'

export const GET_VENDOR = 'GET_VENDOR'

export const GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS'

export const LIST_RESTAURANT_CUSTOMER = "LIST_RESTAURANT_CUSTOMER";

export const RESTAURANT_CUSTOMER_DETAILS = "RESTAURANT_CUSTOMER_DETAILS";

export const GET_WHATSAPP = "GET_WHATSAPP";

export const WHATSAPP_DETAILS = "WHATSAPP_DETAILS";

export const WHATSAPP_FETCHER = "WHATSAPP_FETCHER";

export const FETCHER_DETAILS = "FETCHER_DETAILS";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";

export const UPLOAD_IMAGE_DETAILS = "UPLOAD_IMAGE_DETAILS";







