import {
  ADD_RESTAURANT_TYPE,
  RESTAURANT_TYPE,
  RESTAURANT_TYPE_DETAILS,
  RESTAURANT_TYPE_DELETE,
  UPDATE_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE_DETAILS,
} from "redux/constants/RestaurantTypeConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const RestaurantTypeReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_RESTAURANT_TYPE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        restauranttypeData: action.restauranttypeData,
      };
    case RESTAURANT_TYPE:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case RESTAURANT_TYPE_DETAILS:
      return {
        ...state,
        redirect: "",
        restauranttypeData: action.payload,
        token: action.token,
      };
    case RESTAURANT_TYPE_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_RESTAURANT_TYPE:
      return {
        ...state,
        redirect: "",
        restauranttypeData: action.payload,
        token: action.token,
      };

    case ASSIGN_RESTAURANT_TYPE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        assignRestaurantTypeData: action.assignRestaurantTypeData,
      };

    case ASSIGN_RESTAURANT_TYPE_DETAILS:
      return {
        ...state,
        redirect: "",
        assignRestaurantTypeData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default RestaurantTypeReducer;
