import {
  ADDRESTAURANT,
  LIST_RESTAURANT,
  RESTAURANT_DETAILS,
  RESTAURANT_DELETE,
  UPDATE_RESTAURANT,
  GET_VENDOR,
  GET_VENDOR_DETAILS,
  LIST_RESTAURANT_CUSTOMER,
  RESTAURANT_CUSTOMER_DETAILS,
  WHATSAPP_DETAILS,
  GET_WHATSAPP,
  WHATSAPP_FETCHER,
  FETCHER_DETAILS,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_DETAILS,
} from "redux/constants/RestaurantConstants";

export const addRestaurant = (data) => {
  return {
    type: ADDRESTAURANT,
    payload: data,
  };
};

export const listrestaurant = () => {
  return {
    type: LIST_RESTAURANT,
  };
};

export const restaurantdetails = (data) => {
  return {
    type: RESTAURANT_DETAILS,
    restdata: data,
  };
};

export const restaurantdelete = (id) => {
  return {
    type: RESTAURANT_DELETE,
    payload: id,
  };
};

export const updaterestaurant = (data) => {
  return {
    type: UPDATE_RESTAURANT,
    payload: data,
  };
};

export const getvendor = () => {
  return {
    type: GET_VENDOR,
    // payload: data,
  };
};

export const vendordetails = (data) => {
  return {
    type: GET_VENDOR_DETAILS,
    payload: data,
  };
};
export const getwhatsapp = () => {
  return {
    type: GET_WHATSAPP,
    //  payload: data,
  };
};

export const whatsappdetails = (data) => {
  return {
    type: WHATSAPP_DETAILS,
    payload: data,
  };
};
export const whatsappfetcher = (url,authtoken) => {
  return {
    type: WHATSAPP_FETCHER,
     url: url,
     authtoken:authtoken,
  };
};
export const fetcherdetails = (data) => {
  return {
    type: FETCHER_DETAILS,
    payload: data,
  };
};
export const listrestaurantcustomer = () => {
  return {
    type: LIST_RESTAURANT_CUSTOMER,
    // payload: data,
  };
};
export const restaurantcustomerdetails = (data) => {
  return {
    type: RESTAURANT_CUSTOMER_DETAILS,
    payload: data,
  };
};
export const uploadimage = (data) => {
  return {
    type: UPLOAD_IMAGE,
    payload: data,
  };
};
export const uploadimagedetails = (data) => {
  return {
    type: UPLOAD_IMAGE_DETAILS,
    payload: data,
  };
};
