import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_FEATURES,
  LIST_FEATURES,
  FEATURES_DELETE,
  UPDATE_FEATURES,
  ASSIGN_FEATURES,
  ASSIGN_FEATURES_DETAILS,
  LIST_ASSIGN_FEATURES,
} from "redux/constants/FeaturesConstants";
import {
  featuresdetails,
  assignfeaturesdetails,
  showFeatureMessage,
} from "redux/actions/FeaturesAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from 'antd';

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertFeatureData() {
  yield takeEvery(ADD_FEATURES, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(`/add_features`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getFeatures() {
  yield takeEvery(LIST_FEATURES, function* () {
    try {
      const res = yield axios.get(`/get_features`);
      if (res.status === 200) {
        yield put(featuresdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* featuresDeleteData() {
  yield takeEvery(FEATURES_DELETE, function* ({ payload }) {
    console.log("payload dele", payload);
    try {
      const res = yield axios.get(`/delete_feature/${payload}`);

      if (res.status === 200) {
        // yield put(showFeatureMessage(res.data.message));
        message.success(res.data.message);
        // yield put(floordetails());
        // fork(getFloor);
        console.log(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateFeaturesData() {
  yield takeEvery(UPDATE_FEATURES, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_features/${payload.feature_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* assignFeatureData() {
  yield takeEvery(ASSIGN_FEATURES, function* ({ payload }) {
    const data = { id: payload };

    try {
      const res = yield axios.post(
        `/assign_restaurant_features/${rest_id}`,
        data
      );

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getAssignFeatures() {
  yield takeEvery(LIST_ASSIGN_FEATURES, function* () {
    try {
      const res = yield axios.get(`/get_features_by_restaurant_id/${rest_id}`);
      if (res.status === 200) {
        yield put(assignfeaturesdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertFeatureData),
    fork(getFeatures),
    fork(featuresDeleteData),
    fork(updateFeaturesData),
    fork(assignFeatureData),
    fork(getAssignFeatures),
  ]);
}
