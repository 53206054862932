import {
  ADDFLOOR,
  FLOOR,
  FLOOR_DETAILS,
  FLOOR_DELETE,
  TABLE_FLOOR_DETAILS,
  UPDATE_FLOOR,
  SHOW_FLOOR_MESSAGE,
  HIDE_FLOOR_MESSAGE,
  SHOW_FLOOR_FAILED_MESSAGE,
  HIDE_FLOOR_FAILED_MESSAGE,
  SHOW_FLOOR_DELETE_MESSAGE,
  HIDE_FLOOR_DELETE_MESSAGE,
} from "../constants/FloorConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const FloorReducer = (state = initState, action) => {
  switch (action.type) {
    case ADDFLOOR:
      return {
        ...state,
        redirect: "",
        token: action.token,
        floorData: action.floorData,
      };
    case FLOOR:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case FLOOR_DETAILS: {
      return {
        ...state,
        redirect: "",
        floorData: action.payload,
        token: action.token,
      };
    }
    case FLOOR_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };

    case TABLE_FLOOR_DETAILS: {
      return {
        ...state,
        redirect: "",
        tablefloorData: action.payload,
        token: action.token,
      };
    }

    case UPDATE_FLOOR: {
      return {
        ...state,
        redirect: "",
        floorData: action.payload,
        token: action.token,
      };
    }

    case SHOW_FLOOR_MESSAGE:
      return {
        ...state,
        message: action.message,
        showFloorSuccessMessage: true,
        loading: false,
      };

    case HIDE_FLOOR_MESSAGE:
      return {
        ...state,
        message: "",
        showFloorSuccessMessage: false,
      };

    case SHOW_FLOOR_FAILED_MESSAGE:
      return {
        ...state,
        message: action.message,
        showFloorErrorMessage: true,
        loading: false,
      };

    case HIDE_FLOOR_FAILED_MESSAGE:
      return {
        ...state,
        message: "",
        showFloorErrorMessage: false,
      };

      case SHOW_FLOOR_DELETE_MESSAGE:
      return {
        ...state,
        message: action.message,
        showFloorDeleteMessage: true,
        loading: false,
      };

    case HIDE_FLOOR_DELETE_MESSAGE:
      return {
        ...state,
        message: "",
        showFloorDeleteMessage: false,
      };
    default:
      return state;
  }
};

export default FloorReducer;
