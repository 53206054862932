import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RESTAURANT_IMAGE,
  LIST_RESTAURANT_IMAGE,
  RESTAURANT_IMAGE_DELETE,
  UPDATE_RESTAURANT_IMAGE,
} from "redux/constants/RestaurantImageConstants";
import { restaurantimagedetails } from "redux/actions/RestaurantImageAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertRestaurantImageData() {
  yield takeEvery(ADD_RESTAURANT_IMAGE, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(
        `/add_restaurant_sideimages/${rest_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getRestaurantImage() {
  yield takeEvery(LIST_RESTAURANT_IMAGE, function* () {
    try {
      const res = yield axios.get(`/get_restaurant_sideimages/${rest_id}`);
      if (res.status === 200) {
        yield put(restaurantimagedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateRestaurantImage() {
  yield takeEvery(UPDATE_RESTAURANT_IMAGE, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_restaurant_sideimages/${payload.image_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* restaurantImageDelete() {
  yield takeEvery(RESTAURANT_IMAGE_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/delete_restaurant_sideimages/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertRestaurantImageData),
    fork(getRestaurantImage),
    fork(updateRestaurantImage),
    fork(restaurantImageDelete),
  ]);
}
