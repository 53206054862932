import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADDTABLETYPES,
  LIST_TABLETYPES,
  TABLE_TYPE_DELETE,
  UPDATE_TABLE_TYPES,
} from "redux/constants/TableTypeConstants";
import { addTableType, tabletypedetails } from "redux/actions/TableTypeAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertTableTypeData() {
  yield takeEvery(ADDTABLETYPES, function* ({ payload }) {
    try {
      const res = yield axios.post(`/table-types_store/${rest_id}`, payload);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getTableType() {
  yield takeEvery(LIST_TABLETYPES, function* () {
    try {
      const res = yield axios.get(`/get_table_type/${rest_id}`);
      if (res.status === 200) {
        yield put(tabletypedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* tabletypeDelete() {
  yield takeEvery(TABLE_TYPE_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/table-types_delete/${payload}`);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateTableTypeData() {
  yield takeEvery(UPDATE_TABLE_TYPES, function* ({ payload }) {
    try {
      const res = yield axios.post(
        `/table-types_update/${payload.id}`,
        payload
      );

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertTableTypeData),
    fork(getTableType),
    fork(tabletypeDelete),
    fork(updateTableTypeData),
  ]);
}
