import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_RESTAURANT_TYPE,
  LIST_RESTAURANT_TYPE,
  RESTAURANT_TYPE_DELETE,
  UPDATE_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE,
  ASSIGN_RESTAURANT_TYPE_DETAILS,
} from "redux/constants/RestaurantTypeConstants";
import {
  restauranttypedetails,
  assignrestauranttypedetails,
} from "redux/actions/RestaurantTypeAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* insertRestaurantTypeData() {
  yield takeEvery(ADD_RESTAURANT_TYPE, function* ({ payload }) {
    console.log("payload", payload);
    try {
      const res = yield axios.post(`/add_restaurant_type`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* getRestaurantType() {
  yield takeEvery(LIST_RESTAURANT_TYPE, function* ({ payload }) {
    try {
      const res = yield axios.get(
        payload ? `/get_restaurant_type/?` + payload : `/get_restaurant_type`
      );
      if (res.status === 200) {
        yield put(restauranttypedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* restaurantTypeDelete() {
  yield takeEvery(RESTAURANT_TYPE_DELETE, function* ({ payload }) {
    try {
      const res = yield axios.get(`/restaurant_type_delete/${payload}`);

      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* updateRestaurantType() {
  yield takeEvery(UPDATE_RESTAURANT_TYPE, function* ({ payload }) {
    console.log("updatepayload", payload);
    try {
      const res = yield axios.post(
        `/update_restaurant_type/${payload.type_id}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* assignRestaurantTypeData() {
  yield takeEvery(ASSIGN_RESTAURANT_TYPE, function* ({ payload }) {
    const data = { id: payload };
    console.log("payload", data);
    try {
      const res = yield axios.post(
        `/assign_restaurant_category/${rest_id}`,
        data
      );
      console.log("res", res);
      if (res.status === 200) {
        message.success(res.data.message);
      } else {
        console.log("kaina");
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export function* assignRestaurantTypeDetails() {
  yield takeEvery(LIST_RESTAURANT_TYPE, function* () {
    try {
      const res = yield axios.get(
        `/get_restaurant_type_by_restaurant_id/${rest_id}`
      );
      if (res.status === 200) {
        yield put(assignrestauranttypedetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(insertRestaurantTypeData),
    fork(getRestaurantType),
    fork(restaurantTypeDelete),
    fork(updateRestaurantType),
    fork(assignRestaurantTypeData),
    fork(assignRestaurantTypeDetails),
  ]);
}
