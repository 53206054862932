import {
  ADD_LOCATION,
  LIST_LOCATION,
  LOCATION_DETAILS,
  LOCATION_DELETE,
  UPDATE_LOCATION,
} from "redux/constants/LocationConstants";

export const addLocation = (data) => {
    return {
      type: ADD_LOCATION,
      payload: data,
    };
  };
  
  export const listlocation = () => {
    return {
      type: LIST_LOCATION,
    };
  };
  
  export const locationdetails = (data) => {
    return {
      type: LOCATION_DETAILS,
      payload: data,
    };
  };
  
  export const locationdelete = (id) => {
    return {
      type: LOCATION_DELETE,
      payload: id,
    };
  };
  
  export const updatelocation = (data) => {
    return {
      type: UPDATE_LOCATION,
      payload: data,
    };
  };
  