import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  RESTAURANT_STATS,
  RESTAURANT_STATS_DETAILS,
} from "redux/constants/RestaurantStats";
import {
  restaurantstats,
  restaurantstatsdetails,
} from "redux/actions/RestaurantStatsAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* getRestStats() {
  yield takeEvery(RESTAURANT_STATS, function* ({ payload }) {
    try {
      const res = yield axios.post(`/resraurant_stats/${rest_id}`, payload);
      if (res.status === 200) {
        yield put(restaurantstatsdetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getRestStats)]);
}
