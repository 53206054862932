import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  ADD_CUSTOMER,
  LIST_CUSTOMER,
  CUSTOMER_DELETE,
} from "redux/constants/CustomerConstants";
import { customerDetails } from "redux/actions/CustomerAction";
import axios from "axios";
import { LOGIN_DATA } from "redux/constants/Auth";
import { message } from "antd";

const userD = JSON.parse(localStorage.getItem(LOGIN_DATA));
let rest_id = "";
if (userD) {
  rest_id = userD.restaurant_id;
}

export function* getCustomer() {
  yield takeEvery(LIST_CUSTOMER, function* ({ payload }) {
    try {
      console.log('payloaf', payload);
      const res = yield axios.post(`/restaurant_customer/${rest_id}`, payload);
      if (res.status === 200) {
        yield put(customerDetails(res.data));
      } else {
        // localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        // localStorage.setItem(LOGIN_DATA, JSON.stringify(res.data.data.user));
      }
    } catch (err) {
      console.log("Err", err);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getCustomer)]);
}
