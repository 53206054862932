import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />

        {/* <Route
          path={`${APP_PREFIX_PATH}/floors`}
          component={lazy(() => import(`./floors`))}
        /> */}

        {/* <Route
          path={`${APP_PREFIX_PATH}/table`}
          component={lazy(() => import(`./table`))}
        /> */}

        {/* <Route
          path={`${APP_PREFIX_PATH}/tabletypes`}
          component={lazy(() => import(`./tabletypes`))}
        /> */}

        <Route
          path={`${APP_PREFIX_PATH}/restaurants`}
          component={lazy(() => import(`./restaurants`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/category`}
          component={lazy(() => import(`./discovery/category`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/cuisine`}
          component={lazy(() => import(`./discovery/cuisine`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/features`}
          component={lazy(() => import(`./discovery/features`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/tags`}
          component={lazy(() => import(`./discovery/tags`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/assigncategory`}
          component={lazy(() => import(`./discomanagement/assigncategory`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/assigncuisine`}
          component={lazy(() => import(`./discomanagement/assigncuisine`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/assignfeatures`}
          component={lazy(() => import(`./discomanagement/assignfeatures`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/assigntags`}
          component={lazy(() => import(`./discomanagement/assigntags`))}
        />

        {/* <Route
          path={`${APP_PREFIX_PATH}/restaurantimage`}
          component={lazy(() => import(`./restaurantimage`))}
        /> */}

        <Route
          path={`${APP_PREFIX_PATH}/offers`}
          component={lazy(() => import(`./aboutsection/offers`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/menuimage`}
          component={lazy(() => import(`./aboutsection/menuimage`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/about`}
          component={lazy(() => import(`./aboutsection/about`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/location`}
          component={lazy(() => import(`./aboutsection/location`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/tablemanagement`}
          component={lazy(() => import(`./tablemanagement`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/aboutsection`}
          component={lazy(() => import(`./aboutsection`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/reservation`}
          component={lazy(() => import(`./reservation`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/discomanagement`}
          component={lazy(() => import(`./discomanagement`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/discovery`}
          component={lazy(() => import(`./discovery`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/customer`}
          component={lazy(() => import(`./customer`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/message-campaign`}
          component={lazy(() => import(`./message-campaign`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./users`))}
        />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboards`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
