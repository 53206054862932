export const RESTAURANT_IMAGE = "RESTAURANT_IMAGE";

export const ADD_RESTAURANT_IMAGE = "ADD_RESTAURANT_IMAGE";

export const LIST_RESTAURANT_IMAGE = "LIST_RESTAURANT_IMAGE";

export const RESTAURANT_IMAGE_DETAILS = "RESTAURANT_IMAGE_DETAILS";

export const RESTAURANT_IMAGE_DELETE = "RESTAURANT_IMAGE_DELETE";

export const UPDATE_RESTAURANT_IMAGE = "UPDATE_RESTAURANT_IMAGE";

