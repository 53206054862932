import {
  ADDTABLE,
  TABLE,
  TABLE_DETAILS,
} from "redux/constants/TableConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
  tableData: [],
};

const TableReducer = (state = initState, action) => {
  switch (action.type) {
    case ADDTABLE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        tableData: action.tableData,
      };
    case TABLE:
      return {
        ...state,
        redirect: "",
        // token: action.token,
        tableData: action.payload,
      };
    case TABLE_DETAILS: {
      return {
        ...state,
        redirect: "",
        tableData: action.payload,
        // token: action.token,
      };
    }

    default:
      return state;
  }
};

export default TableReducer;
