import {
  ADD_OFFERS,
  OFFERS,
  OFFERS_DELETE,
  OFFERS_DETAILS,
  UPDATE_OFFERS,
} from "redux/constants/OffersConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const OffersReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_OFFERS:
      return {
        ...state,
        redirect: "",
        token: action.token,
        offersData: action.offersData,
      };
    case OFFERS:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case OFFERS_DETAILS:
      return {
        ...state,
        redirect: "",
        offersData: action.payload,
        token: action.token,
      };
    case OFFERS_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_OFFERS:
      return {
        ...state,
        redirect: "",
        offersData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default OffersReducer;
