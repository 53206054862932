export const TABLETYPES = 'TABLETYPES'; 

export const ADDTABLETYPES = 'ADDTABLETYPES'; 

// export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'

// export const SHOW_LOADING = 'SHOW_LOADING'

export const LIST_TABLETYPES = 'LIST_TABLETYPES'
export const TABLETYPES_DETAILS = 'TABLETYPES_DETAILS'

export const TABLE_TYPE_DELETE = 'TABLE_TYPE_DELETE'; 

export const UPDATE_TABLE_TYPES = 'UPDATE_TABLE_TYPES'; 

