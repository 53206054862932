import {
  ADD_RESTAURANT_IMAGE,
  RESTAURANT_IMAGE,
  RESTAURANT_IMAGE_DETAILS,
  RESTAURANT_IMAGE_DELETE,
  UPDATE_RESTAURANT_IMAGE,
} from "redux/constants/RestaurantImageConstants";
import { AUTH_TOKEN } from "../constants/Auth";

const initState = {
  loading: false,
  message: "",
  redirect: "",
  showMessage: false,
  token: localStorage.getItem(AUTH_TOKEN),
  data: "",
};

const RestaurantImageReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_RESTAURANT_IMAGE:
      return {
        ...state,
        redirect: "",
        token: action.token,
        restaurantimageData: action.restaurantimageData,
      };
    case RESTAURANT_IMAGE:
      return {
        ...state,
        redirect: "",
        token: action.token,
      };
    case RESTAURANT_IMAGE_DETAILS:
      return {
        ...state,
        redirect: "",
        restaurantimageData: action.payload,
        token: action.token,
      };
    case RESTAURANT_IMAGE_DELETE:
      return {
        ...state,
        token: action.token,
        data: action.payload,
      };
    case UPDATE_RESTAURANT_IMAGE:
      return {
        ...state,
        redirect: "",
        restaurantimageData: action.payload,
        token: action.token,
      };
    default:
      return state;
  }
};

export default RestaurantImageReducer;
